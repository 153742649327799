@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@200;300;400;500;600;700;800;900&display=swap');

html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: Barlow, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

li {
  list-style-type: none;
}

a,
a:visited {
  text-decoration: none;
}


a.links,
a.links:visited {
  color: #484848;
  text-decoration: none;
}

a.links:hover {
  text-decoration: underline;
}

a.linksOnDark,
a.linksOnDark:visited {
  color: #a0a0a0;
  text-decoration: none;
}

a.linksOnDark:hover {
  opacity: .7;
}

.cardHover:hover {
  background: linear-gradient(45deg, #da39f2 0%, #3653FB 100%) !important;
  color: white;
}

.fullHeightCard {
  display: flex;
  flex-direction: column;
  height: 100% !important;
}

.fullHeightCard .MuiCardContent-root {
  height: 100% !important;
}

.fullHeightCard .MuiCardActions-root {
  margin-top: auto;
}